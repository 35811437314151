<script lang="ts">
	import { cubicIn } from 'svelte/easing';
	import { fade } from 'svelte/transition';
	import ReducedMotion from "../clientPreferences/ReducedMotion.svelte";

	export let key: any;
	export let duration = 100;
</script>

<ReducedMotion>
	<slot slot="reduce" />

	<svelte:fragment slot="no-preference">
		{#key key}
			<div
				class={$$props.class}
				style={$$props.style}
				in:fade={{ duration, delay: duration, easing: cubicIn }}
				out:fade={{ duration }}
			>
				<slot />
			</div>
		{/key}
	</svelte:fragment>
</ReducedMotion>
