<script lang="ts">
	import Logo from '../header/Logo.svelte';
	import { showModal } from '$internal/utils/modal';
	import { modals } from '$const/ids';
	import Link from "$internal/components/link/Link.svelte";

	type Interactable = {
		label: string;
		title: string;
	} & (Href | Action);

	type Href = Partial<{
		href: string;
		onClick?: never;
	}>;

	type Action = {
		href?: never;
		onClick: () => Promise<void> | void;
	};

	type Section = {
		heading: string;
		children: Interactable[];
	};

	const sections: Section[] = [
		{
			heading: 'product',
			children: [
				{
					label: 'Pricing',
					title: 'Pricing',
					href: '/#pricing'
				}
			]
		},
		{
			heading: 'company',
			children: [
				{
					label: 'Contact',
					title: 'Contact',
					href: '/contact'
				},
				{
					label: 'Affiliate program',
					title: 'Affiliate program',
					href: 'https://l13o.lemonsqueezy.com/affiliates'
				}
			]
		},
		{
			heading: 'legal',
			children: [
				{
					label: 'privacy policy',
					title: 'privacy policy',
					href: '/privacy/policy'
				},
				{
					label: 'refund policy',
					title: 'refund policy',
					href: '/refunds'
				},
				{
					label: 'manage cookies',
					title: 'open the cookies customization modal',
					onClick: () => showModal(modals.privacyConsentCustomize)
				}
			]
		}
	];
</script>

<footer class="p-10 bg-base-200 text-base-content">
	<div class="grid grid-cols-1 sm:grid-cols-2">
		<Logo />
		<div class="mt-10 sm:mt-0 footer mb-8">
			{#each sections as { heading, children }}
				<div>
					<span class="footer-title">{heading}</span>
					{#each children as item}
						{#if item.href}
							<Link href={item.href} title={item.title} fullreload class="link link-hover capitalize">
								{item.label}
							</Link>
						{:else}
							<button on:click={item.onClick} type="button" class="link link-hover capitalize">
								{item.label}
							</button>
						{/if}
					{/each}
				</div>
			{/each}
		</div>
	</div>
	<div class="footer mb-8">© 2023 L13o S.R.L. • All rights reserved</div>
</footer>
