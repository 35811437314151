<script lang="ts">
	import TextShuffle, { type LettersSet } from '../text/TextShuffle.svelte';

	export let tag: keyof HTMLElementTagNameMap = 'article';
	export let from: string;
	export let to: string;

	let shuffled = false;
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<svelte:element
	this={tag}
	tabindex="0"
	{...$$props}
	on:mouseenter={() => (shuffled = true)}
	on:mouseleave={() => (shuffled = false)}
	on:focus={() => (shuffled = true)}
	on:blur={() => (shuffled = false)}
>
	<TextShuffle {from} {to} {shuffled} />
</svelte:element>
