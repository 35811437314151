import { browser } from '$app/environment';
import { readable } from 'svelte/store';

export const reducedMotion = readable(false, (set) => {
	if (!browser) return;
	const reducedMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
	set(reducedMotionQuery.matches);

	reducedMotionQuery.addEventListener('change', change);
	return reducedMotionQuery.removeEventListener('change', change);

	function change(event: MediaQueryListEvent): void {
		set(event.matches);
	}
});
