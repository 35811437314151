<script context="module" lang="ts">
	export type Currency =
		| '$'
		| '€'
		| '£'
		| '¥'
		| '₹'
		| '₽'
		| '฿'
		| '₺'
		| '₡'
		| '₱'
		| '₣'
		| '₴'
		| '₫'
		| '₭'
		| '₩'
		| '₦'
		| '₣'
		| '₮'
		| '₲'
		| '₵'
		| '₤'
		| '₥'
		| '₢'
		| '₿'
		| '₠'
		| '₯'
		| '₣'
		| '₴'
		| '₶'
		| '₷';

	export type PricingCardItem = {
		label: string;
		check?: {
			enabled?: boolean;
			class?: string;
		};
		badge?: {
			label: string;
			class?: string;
		};
	};
</script>

<script lang="ts">
	import SvgCheck from '$internal/components/svg/SvgCheck.svelte';
	import { percentageDiff } from '$internal/utils/math';

	export let name: string;
	export let id = `pricing-${name}`;
	export let currency: Currency = '$';
	export let price: number;
	export let delPrice: number | undefined = undefined;
	export let duration: string | undefined = undefined;
	export let items: PricingCardItem[] = [];
</script>

<li class="w-full max-w-md md:w-auto">
	<article
		class="relative group/pricing p-8 xl:p-10 rounded-3xl shadow-lg hover:-translate-y-1 transition-all border-2 bg-base-200 {$$props.class ??
			'border-neutral'}"
		style="transition: transform 100ms 250ms ease-in-out;"
	>
		{#if $$slots['top-badge']}
			<span class="absolute -top-3.5 left-1/2 -translate-x-1/2">
				<slot name="top-badge" />
			</span>
		{/if}
		<div class="flex items-center justify-between gap-x-4 mb-4">
			<h3 {id} class="text-xl font-semibold leading-8 capitalize">
				{name}
			</h3>

			{#if $$slots['percentage-badge'] && delPrice}
				<slot name="percentage-badge" diff={percentageDiff(delPrice, price)} />
			{/if}
		</div>
		<slot name="description" />
		<p
			class="my-6 flex flex-wrap items-baseline gap-x-4 font-bold brightness-100 group-hover/pricing:brightness-110"
		>
			<span class="flex items-baseline">
				<span class="text-xl opacity-80">
					{currency}
				</span>
				<span class="text-3xl sm:text-4xl tracking-tight">
					{price}
				</span>
				{#if duration}
					<span class="font-normal leading-6 opacity-75 text-sm sm:text-base">
						/{duration}
					</span>
				{/if}
			</span>

			{#if delPrice}
				<del class="ml-auto brightness-75 text-base sm:text-lg">
					{currency}
					{delPrice.toFixed(2)}
				</del>
			{/if}
		</p>
		<slot name="action" />
		{#if items}
			<ul role="list" class="mt-8 space-y-3 text-sm leading-6 xl:mt-10">
				{#each items as item}
					<li class="flex gap-x-3">
						{#if item.check?.enabled}
							<SvgCheck class="w-6 h-6 {item.check.class ?? ''}" />
						{/if}
						<span
							class="w-full flex items-center justify-between {item.check?.enabled
								? 'font-semibold'
								: 'pl-[2.1rem]'}"
						>
							<span class="first-letter:capitalize {item.check?.enabled ? '' : 'opacity-50'}">
								{item.label}
							</span>
							{#if item.badge}
								<span class={item.badge.class ?? 'badge'}>
									{item.badge.label}
								</span>
							{/if}
						</span>
					</li>
				{/each}
			</ul>
		{/if}
	</article>
</li>
