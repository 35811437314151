import { dev } from '$app/environment';

/**
 *
 * @param id {string} - The id of the modal. Good practive is to store them in `/src/internal/const/ids.ts`.
 */
export function showModal(id: string) {
	const modal = document.getElementById(id) as HTMLDialogElement | undefined;
	if (!modal && dev) console.error(`The modal "${id}" is not present in the DOM`);
	modal?.showModal();
}
