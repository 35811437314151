<script lang="ts" context="module">
	export const headerHeightPx = 64;
</script>

<script lang="ts">
	import { page } from '$app/stores';
	import Footer from './footer/Footer.svelte';
	import Header from '$lib/components/Header.svelte';
	import TransitionFade from '../transition/TransitionFade.svelte';

	export let pathname: string = '';
	export let Transition: any = TransitionFade;

	$: hasLeft = Boolean($page.data.layout?.drawer?.left);
	$: hasRight = Boolean($page.data.layout?.drawer?.right);

	$: gridClasses =
		hasLeft && hasRight
			? 'grid-cols-[minmax(150px,25%)_auto_minmax(150px,25%)]'
			: hasLeft
			? 'grid-cols-[minmax(150px,25%)_auto]'
			: hasRight
			? 'gird-cols-[auto_minmax(150px,25%)]'
			: false;
</script>

<div class="min-h-screen grid grid-rows-[1fr_auto]">
	<header
		class="w-screen fixed z-50 flex items-center backdrop-blur-2xl"
		style="height: {headerHeightPx}px"
	>
		<Header />
	</header>

	{#if !gridClasses}
		{#if Transition == null}
			<div style="padding-top: {headerHeightPx}px">
				<slot />
			</div>
		{:else}
			<svelte:component this={Transition} key={pathname} style="padding-top: {headerHeightPx}px">
				<slot />
			</svelte:component>
		{/if}
	{:else}
		<div class="w-screen h-full grid {gridClasses}" style="padding-top: {headerHeightPx}px">
			<svelte:component this={$page.data.layout?.drawer?.left} />

			{#if Transition == null}
				<slot />
			{:else}
				<svelte:component this={Transition} key={pathname}>
					<slot />
				</svelte:component>
			{/if}

			<svelte:component this={$page.data.layout?.drawer?.right} />
		</div>
	{/if}

	<svelte:component this={$page.data.layout?.footer || Footer} />
</div>
