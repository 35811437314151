<script lang="ts" context="module">
	import type { ComponentProps } from 'svelte';
	type PricingCardProps = ComponentProps<PrincingCard>;

	export const base: PricingCardProps = {
		name: 'base',
		price: 39.99,
		delPrice: 60.0,
		duration: 'once',
		currency: '€',
		items: [
			{
				label: 'ZIP',
				check: { enabled: true }
			},
			{
				label: 'SvelteKit boilerplate',
				check: { enabled: true }
			},
			{
				label: 'SEO Tags',
				check: { enabled: true }
			},
			{
				label: 'Stripe Payments',
				check: { enabled: true }
			},
			{
				label: 'Stytch Google OAuth',
				check: { enabled: true }
			},
			{
				label: 'Stytch Magic Links',
				check: { enabled: true }
			},
			{
				label: 'MongoDB with Mongoose',
				check: { enabled: true }
			},
			{
				label: 'Documentation'
			},
			{
				label: 'Updates'
			},
			{
				label: 'Support'
			}
		],
		redirect: 'https://l13o.lemonsqueezy.com/checkout/buy/e9bddb5e-8fc8-4c1c-b5a4-7493074bf6bb'
	};

	export const ready: PricingCardProps = {
		name: 'rapid',
		price: 49.79,
		delPrice: 99.0,
		duration: 'once',
		currency: '€',
		items: [
			{
				label: 'Access to GitHub repository',
				check: { enabled: true, class: 'text-accent' }
			},
			{
				label: 'SvelteKit boilerplate',
				check: { enabled: true, class: 'text-primary' }
			},
			{
				label: 'SEO Tags',
				check: { enabled: true, class: 'text-primary' }
			},
			{
				label: 'Stripe Payments',
				check: { enabled: true, class: 'text-primary' }
			},
			{
				label: 'Stytch Google OAuth',
				check: { enabled: true, class: 'text-primary' }
			},
			{
				label: 'Stytch Magic Links',
				check: { enabled: true, class: 'text-primary' }
			},
			{
				label: 'MongoDB with Mongoose',
				check: { enabled: true, class: 'text-primary' }
			},
			{
				label: 'Documentation',
				check: { enabled: true, class: 'text-accent' }
			},
			{
				label: 'Updates',
				check: { enabled: true, class: 'text-accent' },
				badge: {
					label: 'for one year',
					class: 'badge badge-primary shadow-inner'
				}
			},
			{
				label: 'Support'
			}
		],
		redirect: 'https://l13o.lemonsqueezy.com/checkout/buy/2c114397-5349-49b0-873c-162486965aa9'
	};

	export const unlimited: PricingCardProps = {
		name: 'unlimited',
		price: 119.99,
		delPrice: 179.0,
		duration: 'once',
		currency: '€',
		items: [
			{
				label: 'Access to GitHub repository',
				check: { enabled: true, class: 'text-accent' }
			},
			{
				label: 'SvelteKit boilerplate',
				check: { enabled: true }
			},
			{
				label: 'SEO Tags',
				check: { enabled: true }
			},
			{
				label: 'Stripe Payments',
				check: { enabled: true }
			},
			{
				label: 'Stytch Google OAuth',
				check: { enabled: true }
			},
			{
				label: 'Stytch Magic Links',
				check: { enabled: true }
			},
			{
				label: 'MongoDB with Mongoose',
				check: { enabled: true }
			},
			{
				label: 'Documentation',
				check: { enabled: true, class: 'text-secondary' }
			},
			{
				label: 'Updates',
				check: { enabled: true, class: 'text-secondary' },
				badge: {
					label: 'forever',
					class: 'badge badge-secondary shadow-inner'
				}
			},
			{
				label: 'Support',
				check: { enabled: true, class: 'text-accent' },
				badge: {
					label: 'forever',
					class: 'badge badge-secondary shadow-inner'
				}
			}
		],
		redirect: 'https://l13o.lemonsqueezy.com/checkout/buy/0fe69042-f84e-4286-bdae-88570900ea6a'
	};
</script>

<script lang="ts">
	import PrincingCard from '$internal/components/pricing/PrincingCard.svelte';
	import ButtonTextShuffle from '$internal/components/button/ButtonTextShuffle.svelte';
	import { sections } from '$const/ids';
</script>

<section class="w-screen bg-gradient-to-b from-base-100 to-base-300 relative isolate mt-10 pb-20">
	<header class="mx-auto max-w-4xl text-center px-4">
		<h2
			class="text-base font-semibold leading-7 text-primary-focus scroll-m-20"
			id={sections.landing.pricing}
		>
			Pricing
		</h2>
		<p class="mt-4 text-4xl font-bold tracking-tight sm:text-5xl">
			It is time to <strong
				class="bg-gradient-to-t from-secondary to-primary text-transparent bg-clip-text"
			>
				Ship Rapid
			</strong>
		</p>
	</header>
	<ul class="z-0 mx-auto sm:max-w-screen-xl flex flex-wrap justify-center gap-8 mt-20 px-4">
		<PrincingCard {...base} class="flex-[0_1_150px] border-neutral bg-opacity-90">
			<span
				slot="percentage-badge"
				let:diff
				class="badge badge-neutral shadow-inner cursor-help"
				title={`precisely ${diff.toFixed(2)}%`}
			>
				-30%
			</span>

			<a
				slot="action"
				href={base.redirect}
				class="shadow-2xl btn btn-block btn-neutral normal-case"
			>
				Buy Now
			</a>
		</PrincingCard>

		<PrincingCard
			{...ready}
			class="flex-[0_1_150px] group border-primary/80 hover:border-primary bg-opacity-90"
		>
			<span slot="top-badge" class="badge badge-primary badge-lg shadow-inner">Best value</span>

			<span
				slot="percentage-badge"
				let:diff
				class="badge badge-primary badge-lg shadow-inner cursor-help"
				title={`precisely ${diff.toFixed(2)}%`}
			>
				-50%
			</span>

			<ButtonTextShuffle
				slot="action"
				tag="a"
				href={ready.redirect}
				from="Buy Now"
				to="SHIP RAPID"
				class="shadow-2xl btn btn-block btn-primary group-hover:brightness-110 transition normal-case"
			/>
		</PrincingCard>

		<PrincingCard
			{...unlimited}
			class="flex-[0_1_150px] group border-secondary/80 hover:border-secondary bg-opacity-90"
		>
			<span
				slot="percentage-badge"
				let:diff
				class="badge badge-secondary shadow-inner cursor-help"
				title={`precisely ${diff.toFixed(2)}%`}
			>
				-30%
			</span>

			<ButtonTextShuffle
				slot="action"
				tag="a"
				href={unlimited.redirect}
				from="Buy Now"
				to="GO UNLIMITED"
				class="shadow-2xl btn btn-block btn-secondary opacity-80 group-hover:opacity-100 transition-opacity normal-case"
			/>
		</PrincingCard>
	</ul>
	<svg
		aria-hidden="true"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		version="1.1"
		viewBox="0 386 900 215"
		class="hidden sm:block absolute -bottom-10 -z-10"
		><path
			d="M0 386L21.5 387.5C43 389 86 392 128.8 397.2C171.7 402.3 214.3 409.7 257.2 418.3C300 427 343 437 385.8 441.7C428.7 446.3 471.3 445.7 514.2 448C557 450.3 600 455.7 642.8 450.7C685.7 445.7 728.3 430.3 771.2 428.2C814 426 857 437 878.5 442.5L900 448L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
			fill="#828df8"
		/><path
			d="M0 505L21.5 490.8C43 476.7 86 448.3 128.8 438.5C171.7 428.7 214.3 437.3 257.2 449C300 460.7 343 475.3 385.8 475.7C428.7 476 471.3 462 514.2 461.3C557 460.7 600 473.3 642.8 465C685.7 456.7 728.3 427.3 771.2 421.5C814 415.7 857 433.3 878.5 442.2L900 451L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
			fill="#6c99fd"
		/><path
			d="M0 439L21.5 442C43 445 86 451 128.8 466.8C171.7 482.7 214.3 508.3 257.2 507.3C300 506.3 343 478.7 385.8 460.8C428.7 443 471.3 435 514.2 440C557 445 600 463 642.8 471.5C685.7 480 728.3 479 771.2 470.5C814 462 857 446 878.5 438L900 430L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
			fill="#56a4ff"
		/><path
			d="M0 511L21.5 511.8C43 512.7 86 514.3 128.8 517.3C171.7 520.3 214.3 524.7 257.2 521.8C300 519 343 509 385.8 499C428.7 489 471.3 479 514.2 486C557 493 600 517 642.8 529.8C685.7 542.7 728.3 544.3 771.2 541.7C814 539 857 532 878.5 528.5L900 525L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
			fill="#43aeff"
		/><path
			d="M0 524L21.5 523.8C43 523.7 86 523.3 128.8 530.5C171.7 537.7 214.3 552.3 257.2 559C300 565.7 343 564.3 385.8 563.2C428.7 562 471.3 561 514.2 560.5C557 560 600 560 642.8 558C685.7 556 728.3 552 771.2 544.8C814 537.7 857 527.3 878.5 522.2L900 517L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
			fill="#39b7fc"
		/><path
			d="M0 557L21.5 561.3C43 565.7 86 574.3 128.8 577.7C171.7 581 214.3 579 257.2 579.5C300 580 343 583 385.8 576.8C428.7 570.7 471.3 555.3 514.2 548.8C557 542.3 600 544.7 642.8 546.3C685.7 548 728.3 549 771.2 551.8C814 554.7 857 559.3 878.5 561.7L900 564L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
			fill="#3abff8"
		/>
	</svg>
</section>
