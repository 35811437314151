<script context="module" lang="ts">
	/**
	 * Inspired by <https://www.youtube.com/watch?v=W5oawMJaXbU>
	 */
	const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
</script>

<script lang="ts">
	import { onDestroy } from 'svelte';

	export let from: string;
	export let to: string;
	export let tag: keyof HTMLElementTagNameMap = 'span';
	export let shuffled: boolean;
	export let tick = 30;

	let firstTime = true;
	$: {
		if (firstTime) firstTime = false;
		else shuffled ? shuffle(from, to) : shuffle(to, from);
	}

	let value = from;
	let id: NodeJS.Timeout;
	onDestroy(() => clearInterval(id));

	function shuffle(a: string, b: string): void {
		clearInterval(id);

		a = a.padEnd(b.length);

		let i = 0;
		id = setInterval(() => {
			value = a
				.split('')
				.map((_, index) => {
					if (index > b.length - 1) return '';
					if (index < i) return b[index];

					return letters[Math.floor(Math.random() * letters.length)];
				})
				.join('');

			if (i >= b.length) clearInterval(id);

			i++;
		}, tick);
	}
</script>

<svelte:element this={tag} {...$$props}>{value}</svelte:element>
