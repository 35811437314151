<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	export let label: string | undefined = undefined;
	export let description: string | undefined = undefined;

	export let value: boolean | null = false;
	export let back: [true: string, false: string, null: string] = [
		'bg-primary',
		'bg-base-200',
		'bg-primary/50'
	];
	export let front: [true: string, false: string, null: string] = [
		'bg-neutral',
		'bg-neutral',
		'bg-neutral'
	];

	function toggle(): void {
		if (typeof value === 'boolean') value = !value;
		else value = true;
		dispatch('toggle', value);
	}

	$: backClasses = value === true ? back[0] : value === false ? back[1] : back[2];
	$: frontClasses =
		value === true
			? `translate-x-full ${front[0]}`
			: value === false
			? `translate-x-0 ${front[1]}`
			: `translate-x-[50%] ${front[2]}`;
</script>

<div class="w-fit flex items-center justify-between {$$props.class ?? ''}">
	{#if label || description}
		<span class="flex flex-grow flex-col">
			{#if label}
				<span class="text-sm font-medium leading-6" id="availability-label">
					{label}
				</span>
			{/if}
			{#if description}
				<span class="text-sm" id="availability-description">
					{description}
				</span>
			{/if}
		</span>
	{/if}

	<button
		type="button"
		class="{backClasses} relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 {$$props.class ??
			''}"
		role="switch"
		aria-checked={value}
		on:click={toggle}
	>
		<span
			aria-hidden="true"
			class="{frontClasses} pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out"
		/>
	</button>
</div>
