export const modals = {
	privacyConsentCustomize: 'privacy-consent-customize'
} as const;

export const sections = {
	landing: {
		pricing: 'pricing',
		faq: 'faq'
	}
} as const;
