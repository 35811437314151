<script lang="ts">
	import { fallbackDarkKey, fallbackLightKey } from '$const/theme';
	import { title } from '$const/website';
	import Logo from '$internal/components/layout/header/Logo.svelte';
	import Link from "$internal/components/link/Link.svelte";
	import Toggle from '$internal/components/toggle/Toggle.svelte';
	import { theme } from '$internal/stores/theme';
	import { ready } from "./landing/pricing/PrincingCards.svelte";

	let value: boolean | null = null;

	theme.subscribe(($theme) => {
		switch ($theme) {
			case fallbackDarkKey:
				value = true;
				return;
			case fallbackLightKey:
				value = false;
				return;
			default:
				value = null;
		}
	});

	function toggle(event: CustomEvent<Toggle>): void {
		theme.set(event.detail ? fallbackDarkKey : fallbackLightKey);
	}
</script>

<nav
	class="w-full h-full bg-transparent px-4 sm:px-8 my-auto flex justify-between items-center gap-4"
>
	<Logo class="flex-shrink-0 w-24 sm:w-28"/>

	<Link href="/details" title="read more about the features in the details page" fullreload class="hidden sm:inline-flex btn btn-sm btn-ghost ml-auto normal-case">Details</Link>

	<Link href="/#pricing" title="see pricing" fullreload class="hidden sm:inline-flex btn btn-sm btn-ghost normal-case">Pricing</Link>

	<a
		href={ready.redirect}
		class="ml-auto sm:ml-0 btn btn-sm w-fit btn-primary hover:btn-primary focus:btn-primary transition-colors normal-case"
	>
		Get {title}
	</a>

	<Toggle bind:value on:toggle={toggle}/>
</nav>
